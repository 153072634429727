"use client";
import Script from "next/script";
import { GA_ID, useGtag } from "../../lib/gtag";
import { User } from "@/app/types";

const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID;
const FB_ID = process.env.NEXT_PUBLIC_FB_ID;

export function Analytics({ user }: { user: User }) {
  useGtag();

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}
      />
      <Script
        id="google-tag--code"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            function analyticsGrouping(path) {
              if (path.includes("/kitas/") || path.endsWith("/kitas")) return "kitas";
              if (path.includes("/events/") || path.endsWith("/events")) return "events";
              if (path.includes("/blog/") || path.endsWith("/blog")) return "blog";
              if (path.includes("/timeline/") || path.endsWith("/timeline"))
                return "timeline";
              if (path.includes("/perks/") || path.endsWith("/perks")) return "perks";
              if (path.includes("/guides/") || path.endsWith("/guides")) return "guides";
              return "other";
            }

            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_ID}', {
              page_path: window.location.pathname,
              content_group: analyticsGrouping(window.location.pathname),
              isLoggedIn: ${user.isLoggedIn},
              isSubscribed: ${user.isSubscribed},
              dob: '${user.profile?.childDob?.slice(0, 7) ?? null}',
              neighborhood: '${user.profile?.neighborhood ?? null}',
            });
          `,
        }}
      />
      {HOTJAR_ID && (
        <Script id="hotjar" strategy="afterInteractive">
          {`
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${HOTJAR_ID},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
        </Script>
      )}
      {FB_ID && (
        <Script id="facebook" strategy="afterInteractive">
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${FB_ID}');
          fbq('track', 'PageView');
        `}
        </Script>
      )}
    </>
  );
}
